import React from 'react';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';

export interface FooterProps {}

const Footer: React.FC<FooterProps> = props => {
    return (
        <footer className="text-center mb-4">
            <Container>
                Made with <FontAwesomeIcon className="text-red" icon={faHeart} /> by{' '}
                <a href="https://andrewdyer.rocks/" target="_blank">
                    Andrew Dyer
                </a>
            </Container>
        </footer>
    );
};

export default Footer;
