import React from 'react';
import { RoundedHero } from '@typedbootstrap/ui-kit-pro-components';
import { Button } from 'reactstrap';

export interface HeaderProps {
    onButtonClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onButtonClick }) => {
    return (
        <RoundedHero
            bg="yellow"
            className="mb-4"
            color="light"
            title="Git Commit Emoji"
            text="Add colour to your commit messages by prepending them with an emoji!"
            children={
                <div className="mt-4">
                    <Button type="button" color="white" onClick={onButtonClick} size="lg" outline>
                        Generate Commit Message
                    </Button>
                </div>
            }
            dark
            gradient
        />
    );
};

export default Header;
