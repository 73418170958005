import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';

export interface SearchFormProps {
    onChange(input: string): void;
}

const SearchForm: React.FC<SearchFormProps> = props => {
    const { onChange } = props;

    return (
        <Form>
            <FormGroup>
                <Input
                    type="text"
                    name="input"
                    placeholder="Search for a git commit emoji..."
                    onChange={e => onChange(e.target.value)}
                    bsSize="lg"
                />
            </FormGroup>
        </Form>
    );
};

export default SearchForm;
