import React, { useState } from 'react';
import { DefaultTemplate } from '@typedbootstrap/ui-kit-pro-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import { Footer, Header, SearchForm } from './components/organisms';
import data from './emojis';
import messages from './messages';

export type Emoji = {
    id: number;
    name: string;
    colour: string;
    emoji: string;
    shortcode: string;
};

const App: React.FC = () => {
    const [emojis, setEmojis] = useState<Emoji[]>(data);

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const filterEmojis = (input: string): void => {
        if (input) {
            let filteredEmojis = [];

            for (let i = 0; i < emojis.length; i = i + 1) {
                if (emojis[i].name.toLowerCase().indexOf(input) !== -1) {
                    filteredEmojis.push(emojis[i]);
                }
            }
            setEmojis(filteredEmojis);
        } else {
            setEmojis(data);
        }
    };

    const notifyMessageCopied = (text: string) => {
        toast.success(`Message "${text}" copied to clipboard!`);
        setModal(false);
    };

    const notifyShortcodeCopied = (text: string) => {
        toast.success(`Shortcode ${text} copied to clipboard!`);
    };

    return (
        <DefaultTemplate footer={<Footer />}>
            <Header onButtonClick={toggle} />
            <Container>
                <SearchForm onChange={filterEmojis} />
                <Row className="justify-content-center">
                    {emojis.map(emoji => (
                        <Col key={emoji.id} xs="12" sm="6" md="3">
                            <CopyToClipboard onCopy={notifyShortcodeCopied} text={emoji.shortcode}>
                                <Card
                                    className="lift my-3 text-center user-select-none"
                                    style={{ cursor: 'pointer' }}>
                                    <CardBody style={{ background: emoji.colour }}>
                                        <h1 className="display-3">{emoji.emoji}</h1>
                                    </CardBody>
                                    <CardBody>
                                        <CardTitle tag="h5" className="m-0">
                                            {emoji.name}
                                        </CardTitle>
                                    </CardBody>
                                </Card>
                            </CopyToClipboard>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Generate Commit Message</ModalHeader>
                <ModalBody>
                    <p className="text-center">Select the type of change that you're committing</p>
                    <ListGroup>
                        {messages.map(message => (
                            <ListGroupItem key={message.id} action>
                                <CopyToClipboard
                                    onCopy={notifyMessageCopied}
                                    text={`${message.name}: ${message.shortcode}`}>
                                    <div className="d-flex align-items-center flex-shrink-0 me-3">
                                        <div className="me-3">{message.emoji}</div>
                                        <div className="d-flex flex-column fw-bold">
                                            <div className="text-dark line-height-normal mb-1">
                                                {message.description}
                                            </div>
                                        </div>
                                    </div>
                                </CopyToClipboard>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ModalBody>
            </Modal>
        </DefaultTemplate>
    );
};

export default App;
