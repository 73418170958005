type Message = {
    id: number;
    name: string;
    description: string;
    emoji: string;
    shortcode: string;
};

const messages: Message[] = [
    {
        id: 0,
        name: 'feat',
        description: 'Added a new feature',
        emoji: '✨',
        shortcode: ':sparkles:'
    },
    {
        id: 1,
        name: 'fix',
        description: 'Fixed a bug',
        emoji: '🐛',
        shortcode: ':bug:'
    },
    {
        id: 2,
        name: 'docs',
        description: 'Updated the documentation',
        emoji: '📚',
        shortcode: ':books:'
    },
    {
        id: 3,
        name: 'style',
        description:
            'Made a change that do not affect the meaning of the code (white-space, formatting, missing semi-colons, etc)',
        emoji: '🎨',
        shortcode: ':art:'
    },
    {
        id: 4,
        name: 'refactor',
        description: 'Made a code change that neither fixes a bug nor adds a feature',
        emoji: '🔨',
        shortcode: ':hammer:'
    },
    {
        id: 5,
        name: 'perf',
        description: 'Made a code change that improves performance',
        emoji: '🐎',
        shortcode: ':racehorse:'
    },
    {
        id: 6,
        name: 'test',
        description: 'Made a code change that improves the maintainability',
        emoji: '🚨',
        shortcode: ':rotating_light:'
    },
    {
        id: 7,
        name: 'chore',
        description:
            'Made a change to the build process or auxiliary tools and libraries such as documentation generation',
        emoji: '🔧',
        shortcode: ':wrench:'
    },
    {
        id: 8,
        name: 'chore(release)',
        description: 'Code deployment or publishing to external repositories',
        emoji: '🚀',
        shortcode: ':rocket:'
    },
    {
        id: 9,
        name: 'chore(deps)',
        description: 'Added or deleted dependencies',
        emoji: '🔗',
        shortcode: ':link:'
    },
    {
        id: 10,
        name: 'build',
        description: 'Made a change related to build processes',
        emoji: '📦',
        shortcode: ':package:'
    },
    {
        id: 11,
        name: 'ci',
        description: 'Updated to the continuous integration system',
        emoji: '👷',
        shortcode: ':construction_worker:'
    },
    {
        id: 12,
        name: 'release',
        description: 'Code deployment or publishing to external repositories',
        emoji: '🚀',
        shortcode: ':rocket:'
    },
    {
        id: 13,
        name: 'security',
        description: 'Fixed security issues',
        emoji: '🔒',
        shortcode: ':lock:'
    },
    {
        id: 14,
        name: 'i18n',
        description: 'Internationalization and localization',
        emoji: '👽',
        shortcode: ':alien:'
    },
    {
        id: 15,
        name: 'breaking',
        description: 'Introducing breaking changes',
        emoji: '💥',
        shortcode: ':boom:'
    },
    {
        id: 15,
        name: 'config',
        description: 'Changed configuration files',
        emoji: '🔧',
        shortcode: ':wrench:'
    },
    {
        id: 16,
        name: 'add',
        description: 'Added something',
        emoji: '➕',
        shortcode: ':heavy_plus_sign:'
    },
    {
        id: 17,
        name: 'remove',
        description: 'Removed something',
        emoji: '➖',
        shortcode: ':heavy_minus_sign:'
    }
];

export default messages;
