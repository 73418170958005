const emojis = [
    {
        id: 0,
        colour: '#F74D5E',
        emoji: '🎉',
        name: 'First commit',
        shortcode: ':tada:'
    },
    {
        id: 1,
        colour: '#80DEEA',
        emoji: '🔖',
        name: 'Version tag',
        shortcode: ':bookmark:'
    },
    {
        id: 2,
        colour: '#FFE55F',
        emoji: '✨',
        name: 'New feature',
        shortcode: ':sparkles:'
    },
    {
        id: 3,
        colour: '#8BD843',
        emoji: '🐛',
        name: 'Bugfix',
        shortcode: ':bug:'
    },
    {
        id: 4,
        colour: '#CEDAE6',
        emoji: '📇',
        name: 'Metadata',
        shortcode: ':card_index:'
    },
    {
        id: 5,
        colour: '#C41F1C',

        emoji: '📚',
        name: 'Documentation',
        shortcode: ':books:'
    },
    {
        id: 6,
        colour: '#FFCE49',
        emoji: '💡',
        name: 'Documenting source code',
        shortcode: ':bulb:'
    },
    {
        id: 7,
        colour: '#00D42D',

        emoji: '🐎',
        name: 'Performance',
        shortcode: ':racehorse:'
    },
    {
        id: 8,
        colour: '#80DEEA',
        emoji: '💄',
        name: 'Cosmetic',
        shortcode: ':lipstick:'
    },
    {
        id: 9,
        colour: '#526CFE',
        emoji: '🚨',
        name: 'Tests',
        shortcode: ':rotating_light:'
    },
    {
        id: 10,
        colour: '#76E956',
        emoji: '✅',
        name: 'Adding a test',
        shortcode: ':white_check_mark:'
    },
    {
        id: 11,
        colour: '#3FC4FE',

        emoji: '⚡',
        name: 'General update',
        shortcode: ':zap:'
    },
    {
        id: 12,
        colour: '#FF7181',

        emoji: '🎨',
        name: 'Improve format/structure',
        shortcode: ':art:'
    },
    {
        id: 13,
        colour: '#FFC502',

        emoji: '🔨',
        name: 'Refactor code',
        shortcode: ':hammer:'
    },
    {
        id: 14,
        colour: '#FF9C43',

        emoji: '🔥',
        name: 'Removing code/files',
        shortcode: ':fire:'
    },
    {
        id: 15,
        colour: '#C5E763',

        emoji: '💚',
        name: 'Continuous Integration',
        shortcode: ':green_heart:'
    },
    {
        id: 16,
        colour: '#FFCE49',

        emoji: '🔒',
        name: 'Security',
        shortcode: ':lock:'
    },
    {
        id: 17,
        colour: '#00E676',

        emoji: '⬆',
        name: 'Upgrading dependencies',
        shortcode: ':arrow_up:'
    },
    {
        id: 18,
        colour: '#EF534F',

        emoji: '⬇',
        name: 'Downgrading dependencies',
        shortcode: ':arrow_down:'
    },
    {
        id: 19,
        colour: '#80DEEA',

        emoji: '👕',
        name: 'Lint',
        shortcode: ':shirt:'
    },
    {
        id: 20,
        colour: '#C5E763',

        emoji: '👽',
        name: 'Translation',
        shortcode: ':alien:'
    },
    {
        id: 21,
        colour: '#FFCE49',

        emoji: '📝',
        name: 'Text',
        shortcode: ':pencil:'
    },
    {
        id: 22,
        colour: '#FB584A',

        emoji: '🚑',
        name: 'Critical hotfix',
        shortcode: ':ambulance:'
    },
    {
        id: 23,
        colour: '#03A9F0',

        emoji: '🚀',
        name: 'Deploying stuff',
        shortcode: ':rocket:'
    },
    {
        id: 24,
        colour: '#F94E43',

        emoji: '🍎',
        name: 'Fixing on MacOS',
        shortcode: ':apple:'
    },
    {
        id: 25,
        colour: '#FA9A3F',

        emoji: '🐧',
        name: 'Fixing on Linux',
        shortcode: ':penguin:'
    },
    {
        id: 26,
        colour: '#FF7181',

        emoji: '🏁',
        name: 'Fixing on Windows',
        shortcode: ':checkered_flag:'
    },
    {
        id: 27,
        colour: '#FFB84D',

        emoji: '🚧 ',
        name: 'Work in progress',
        shortcode: ':construction:'
    },
    {
        id: 28,
        colour: '#6DC8FF',

        emoji: '👷',
        name: 'Adding CI build system',
        shortcode: ':construction_worker:'
    },
    {
        id: 29,
        colour: '#CEDAE6',

        emoji: '📈',
        name: 'Analytics or tracking code',
        shortcode: ':chart_with_upwards_trend:'
    },
    {
        id: 30,
        colour: '#EF534F',

        emoji: '➖',
        name: 'Removing a dependency',
        shortcode: ':heavy_minus_sign:'
    },
    {
        id: 31,
        colour: '#00E676',

        emoji: '➕',
        name: 'Adding a dependency',
        shortcode: ':heavy_plus_sign:'
    },
    {
        id: 32,
        colour: '#00EBFF',

        emoji: '🐳',
        name: 'Docker',
        shortcode: ':whale:'
    },
    {
        id: 33,
        colour: '#55D2D8',

        emoji: '🔧',
        name: 'Configuration files',
        shortcode: ':wrench:'
    },
    {
        id: 34,
        colour: '#FDD0AE',

        emoji: '📦',
        name: 'Package.json in JS',
        shortcode: ':package:'
    },
    {
        id: 35,
        colour: '#55D2D8',

        emoji: '🔀',
        name: 'Merging branches',
        shortcode: ':twisted_rightwards_arrows:'
    },
    {
        id: 36,
        colour: '#C7A281',

        emoji: '💩',
        name: 'Bad code / need improv.',
        shortcode: ':hankey:'
    },
    {
        id: 37,
        colour: '#55D2D8',

        emoji: '⏪',
        name: 'Reverting changes',
        shortcode: ':rewind:'
    },
    {
        id: 38,
        colour: '#F94E28',

        emoji: '💥',
        name: 'Breaking changes',
        shortcode: ':boom:'
    },
    {
        id: 39,
        colour: '#FFD433',

        emoji: '👌',
        name: 'Code review changes',
        shortcode: ':ok_hand:'
    },
    {
        id: 40,
        colour: '#00B1FB',

        emoji: '♿',
        name: 'Accessibility',
        shortcode: ':wheelchair:'
    },
    {
        id: 41,
        colour: '#EF584A',

        emoji: '🚚',
        name: 'Move/rename repository',
        shortcode: ':truck:'
    },
    {
        id: 42,
        colour: '#ffce49',
        emoji: '✏️',
        name: 'Fixing typos',
        shortcode: ':pencil2:'
    }
];

export default emojis;
